import { useState } from 'react';
import {
    Card,
    CardHeader,
    Heading,
    CardBody,
    Stack,
    StackDivider,
    Box,
    Text,
    Badge,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Select,
    Progress,
    NumberInput,
    NumberInputField,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderTrack,
    SliderThumb,
    Tooltip
} from '@chakra-ui/react';

import { flatData } from './Data';

export function FlatSelectionCard({ setSelectedFlat, onRoomSelected, onNumAdultsSelected }) {
    const [levelOptions, setLevelOption] = useState([{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }, { value: 3, label: "3. OG" }]);
    const [flatOptions, setFlatOptions] = useState([]);
    const [roomOptions, setRoomOptions] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState();

    function buildingSelected(e) {
        var newOptions;
        if (e.target.value === "south") {
            newOptions = [{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }];
        } else {
            newOptions = [{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }, { value: 3, label: "3. OG" }];
        }
        setLevelOption(newOptions);
        setSelectedBuilding(e.target.value);
        setSelectedFlat(null);
        onRoomSelected(null);
    };

    function levelSelected(e) {
        var newData = flatData[selectedBuilding][e.target.value];
        if (newData) {
            setFlatOptions(flatData[selectedBuilding][e.target.value]);
        }
        setSelectedFlat(null);
        onRoomSelected(null);
    }

    function flatSelected(e) {
        var index = e.target.value;
        var flat = flatOptions[index];
        if (flat.wgRoomList) {
            setRoomOptions(flat.wgRoomList);
        }
        setSelectedFlat(flatOptions[index]);
        onRoomSelected(null);
    }
    function roomSelected(e) {
        onRoomSelected(roomOptions[e.target.value]);
    }

    function numAdultsSelected(e) {
        onNumAdultsSelected(e.target.value);
    }
    return <Card>
        <CardHeader>
            <Heading size='md'>Wohnungsauswahl</Heading>
        </CardHeader>
        <CardBody>
            <Stack spacing={4} >
                <Select placeholder='Gebäude auswählen' onChange={buildingSelected}>
                    <option value='north'>Nordbau</option>
                    <option value='south'>Südbau</option>
                </Select>
                <Select placeholder='Etage auswählen' onChange={levelSelected}>
                    {levelOptions.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                </Select>
                <Select placeholder='Wohnung auswählen' onChange={flatSelected}>
                    {flatOptions.map((flat, index) => <option value={index} >{flat.print()}</option>)}
                </Select>
                {roomOptions.length > 0 ? (<Select placeholder='Zimmer auswählen' onChange={roomSelected}>
                    {roomOptions.map((room, index) => <option value={index} >{room.print()} ({room.size} m²)</option>)}
                </Select>) : null}
                <Select placeholder='Anzahl Erwachsene' onChange={numAdultsSelected}>
                    <option value="1">1 erwachsene Person</option>
                    <option value="2">2 erwachsene Personen</option>
                </Select>
            </Stack>
        </CardBody>
    </Card >
}

export function FlatDetailsCard({ flat, room, flatRent }) {
    var body = <CardBody>
        Bitte auswählen
    </CardBody>;

    var badges = "";

    if (flat && (flat.wgRoomList.length === 0 || room) && flatRent) {
        var sizePrivate = flat.sizePrivate;
        if (room) {
            sizePrivate = room.size;
        }

        var sizeSharedBox = null;

        var internalAreaShare = flat.calcInternalAreaShare(room);
        var totalSizeToPay = flat.calcTotalPayedArea(room);

        if (flat.wgRoomList.length > 0) {

            sizeSharedBox = [
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Anteil Interne Gemeinschaftsfläche (WG und Cluster)
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {internalAreaShare.toFixed(2)} m²
                    </Text>
                </Box >,
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Zahlfläche
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {sizePrivate.toFixed(2)} m² + {internalAreaShare.toFixed(2)} m² (WG/Cluster-Fläche)
                    </Text>
                    <Text pt={3} size='s' fontWeight='bold'>Gesamt: {totalSizeToPay.toFixed(2)} m² </Text>
                </Box>
            ];

        }

        body = <CardBody>

            <Stack divider={<StackDivider />} spacing='4'>

                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Bezeichnung
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {flat.name} {room ? "Zimmer: " + room.print() : null}
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Zimmeranzahl und Gesamtfläche
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {flat.numRooms} Zimmer, {flat.sizePrivate.toFixed(2)} m²
                    </Text>
                </Box>
                {sizeSharedBox}
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Mindestmiete
                    </Heading>
                    <Text fontSize="xs">Die Mindestmiete ergibt sich aus dem WBS Satz für die private Fläche (9,45 €/m²), den Nebenkosten (1,20 €/m²) und der Küchen-Nutzungspauschale von 50€ pro Küche (in Clustern und WGs auf Parteien aufgeteilt)</Text>
                    <Text pt='2' fontSize='xl' fontWeight='bold'>
                        {(flatRent.min).toFixed(2)} €
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Deckungsmiete WBS
                    </Heading>
                    <Text fontSize="xs">Die Deckungsmiete beinhaltet einen Beitrag von 60 € pro erwachsener Person für die Gemeinschaftsflächen</Text>
                    <Text pt='2' fontSize='xl' fontWeight='bold'>
                        {(flatRent.minCovering).toFixed(2)} €
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Deckungsmiete freifinanziert {flat.isWbs ? "(zum Vergleich)" : ""}
                    </Heading>
                    <Text fontSize="xs">Das ist die Miete, die für diese Wohnung ohne solidarischen Ausgleich anfallen würde{flat.isWbs ? ", wenn Sie frei finanziert wäre " : " "}und ergibt sich aus 13,06 €/m² plus 1,20 €/m² Nebenkosten, Küchen-Nutzungspauschale und Gemeinschaftsflächen-Pauschale.</Text>
                    <Text pt='2' fontSize='xl' fontWeight='bold'>
                        {(flatRent.maxCovering).toFixed(2)} €
                    </Text>
                </Box>
            </Stack>
        </CardBody>;

        if (flat.isWbs) {
            badges = <Badge colorScheme='blue'>
                mit WBS
            </Badge>;
        } else {
            badges = <Badge colorScheme='yellow'>
                ohne WBS
            </Badge>;
        }
    }

    return (
        <Card>

            <CardHeader>
                {badges}
                <Heading size='md'>Wohnungsdetails</Heading>
            </CardHeader>
            {body}
        </Card>
    );
}

export function SelfEvaluationCard({ rent, setRent }) {

    const [sliderValue, setSliderValue] = useState(30)
    const [showTooltip, setShowTooltip] = useState(false)

    const [income, setIncome] = useState(0)

    const formatEuro = (val) => val + ' €';
    const parseEuro = (val) => val.replace(/^€/, '')


    function onIncomeChange(valueString) {
        setIncome(parseEuro(valueString));
        setRent(valueString * sliderValue / 100);
    }

    function onSliderChange(value) {
        setSliderValue(value);
        setRent(income * value / 100);
    }

    return <Card>
        <CardBody maxW={500}>
            <Stack spacing={3}>
                <Text>Wie hoch ist euer/dein Haushaltsnetto? Beachtet dabei alle regelmäßigen Einkünfte wie Gehalt, Rente, Mieteinkünfte oder Kapitalerträge </Text>
                <NumberInput
                    onChange={onIncomeChange}
                    value={formatEuro(income)}
                >
                    <NumberInputField />
                </NumberInput>

                <Text>Wie viel Prozent eures/deines Nettoeinkommens kannst du für Miete aufbringen?</Text>
                <Slider
                    id='slider'
                    defaultValue={30}
                    min={10}
                    max={50}
                    mb={5}
                    colorScheme='teal'
                    onChange={onSliderChange}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <SliderMark value={10} mt='1' ml='-2.5' fontSize='sm'>
                        10%
                    </SliderMark>
                    <SliderMark value={20} mt='1' ml='-2.5' fontSize='sm'>
                        20%
                    </SliderMark>
                    <SliderMark value={30} mt='1' ml='-2.5' fontSize='sm'>
                        30%
                    </SliderMark>
                    <SliderMark value={40} mt='1' ml='-2.5' fontSize='sm'>
                        40%
                    </SliderMark>
                    <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                        50%
                    </SliderMark>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg='teal.500'
                        color='white'
                        placement='top'
                        isOpen={showTooltip}
                        label={`${sliderValue}%`}
                    >
                        <SliderThumb />
                    </Tooltip>


                </Slider>
            </Stack>
        </CardBody>
    </Card>
}

export function ResultsCard({ rent, flat, room, numAdults, flatRent }) {
    var body = <CardBody></CardBody>
    if (flat && (room || flat.wgRoomList.length === 0) && flatRent) {
        var totalSize = flat.calcTotalPayedArea(room)
        var relativeRent = (rent) / totalSize;

        var rangePosition = ((rent - flatRent.min) / (flatRent.maxCovering - flatRent.min)) * 100;
        if (rangePosition < 0) { rangePosition = 0; }
        if (rangePosition > 100) { rangePosition = 100; }
        var color = "green";
        if (rangePosition > 33) { color = "yellow"; }
        if (rangePosition > 66) { color = "red"; }
        body = <CardBody>
            <Stack>
                <StatGroup>
                    <Stat>
                        <StatLabel>Miete</StatLabel>
                        <StatNumber>{rent} €</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel>Pro m²</StatLabel>
                        <StatNumber>{relativeRent.toFixed(2)} € </StatNumber>
                    </Stat>
                </StatGroup>
                <Text>
                    Im Folgenden siehst du, wo deine Miete auf der Spanne zwischen WBS-Satz und
                    frei finanzierten Wohnungen ohne Soli-Ausgleich liegen würde.
                </Text>
                <Progress colorScheme={color} size='sm' value={rangePosition} />
                <StatGroup>
                    <Stat>
                        <StatLabel>Mindestmiete</StatLabel>
                        <StatNumber>{flatRent.min.toFixed(2)} €</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel>Deckungsmiete freifinanziert</StatLabel>
                        <StatNumber>{flatRent.maxCovering.toFixed(2)} € </StatNumber>
                    </Stat>
                </StatGroup>
            </Stack>
        </CardBody>;
    }

    return <Card>
        {body}
    </Card>
}