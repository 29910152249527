class Flat {
    constructor(name, numRooms, sizePrivate, isWbs = false, isAccessible = false, wgRoomList = null) {
        this.name = name;
        this.numRooms = numRooms;
        this.sizePrivate = sizePrivate;
        this.isWbs = isWbs;
        this.sizeShared = 0;
        // Normal flats have as many people as rooms
        // WGs and cluster flats have one more room
        if (!wgRoomList) {
            this.wgRoomList = [];
            this.numPeople = numRooms;
        } else {
            this.wgRoomList = wgRoomList;
            this.sizeShared = this.sizePrivate;
            for (var i = 0; i < this.wgRoomList.length; i++) {
                this.sizeShared -= this.wgRoomList[i].size;
            }
            this.numPeople = numRooms - 1;
        }

    }

    // Returns a string representation of the flat
    print() {
        return this.name + " (" + this.numRooms + " Zimmer)";
    }

    // Calculates the share of the internal shared area the party has to pay for
    // This is only applicable for WGs and clusters, i.e. when a room is selected.
    calcInternalAreaShare(room = null) {
        return room ? this.sizeShared / this.numPeople * room.numPeople : 0;
    }

    // Calculates the total area the party has to pay for
    // Includes the private area and if applicable share of 
    // the internal common area of WGs and cluster flats
    calcTotalPayedArea(room = null) {
        if (!room) {
            return this.sizePrivate;
        } else {
            return room.size + this.calcInternalAreaShare(room);
        }
    }
}

class Room {
    constructor(name, size, numPeople = 1) {
        this.name = name;
        this.size = size;
        this.numPeople = numPeople;
    }

    print() {
        return this.name;
    }
}

export const flatData = {
    "north": {
        0: [
            new Flat("W 0.1 (WG)", 4, 84.834, false, false, [
                new Room("0.1 02", 12.523), new Room("0.1 03", 12.102), new Room("0.1 04", 14.746)
            ]),
            new Flat("W 0.2", 2, 53.453, true),
            new Flat("W 0.3", 3, 63.498, true)
        ],
        1: [
            new Flat("W 1.1", 4, 84.794, true),
            new Flat("W 1.2", 1, 36.469, true),
            new Flat("W 1.3 (WG)", 4, 63.128, true, false, [
                new Room("1.3 02", 11.434), new Room("1.3 03", 11.245), new Room("1.3 04", 11.77)
            ])
        ],
        2: [
            new Flat("W 2.1", 4, 84.794, true),
            new Flat("W 2.2", 2, 46.261, true),
            new Flat("W 2.3 (Cluster)", 5, 154.196, true, true, [
                new Room("1 Person", 28.774), new Room("2 Personen", 46.32, 2), new Room("1 Person (rollstuhlgerecht)", 41.814)
            ]),
            new Flat("W 2.4", 2, 64.265, true, true)
        ],
        3: [
            new Flat("W 3.1", 4, 84.794),
            new Flat("W 3.2 (Cluster)", 5, 154.97, true, true, [
                new Room("1 Person", 28.531), new Room("2 Personen", 47.146, 2), new Room("1 Person (rollstuhlgerecht)", 44.036)
            ]),
            new Flat("W 3.3", 3, 63.128, true)
        ]
    },
    "south": {
        0: [
            new Flat("W 0.4", 2, 54.905, true),
            new Flat("W 0.5", 4, 83.416, true),
            new Flat("W 0.6", 5, 107.733, true),
        ],
        1: [
            new Flat("W 1.4", 2, 59.442),
            new Flat("W 1.5 (WG)", 5, 89.209, true, false,
                [new Room("1.5 02", 15.448), new Room("1.5 05", 11.489), new Room("1.5 06", 13.462)]
            ),
            new Flat("W 1.6.0", 1, 30.431),
            new Flat("W 1.6", 5, 108.761),
        ],
        2: [
            new Flat("W 2.5", 2, 58.882),
            new Flat("W 2.6", 4, 89.853, true),
            new Flat("W 2.7", 5, 101.533)
        ]
    }
}
const addedCost = 1.2;
export const minRent = 9.45 + addedCost;
export const maxRent = 13.06 + addedCost;
